import React from 'react';
import Layout from '../components/organisms/layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>404: Not Found</h1>
      <p>お探しのページは見つかりませんでした。</p>
    </Layout>
  );
};
export default NotFoundPage;
